import Layout from 'components/Layout'
import Resource from 'components/global/Resource'
import { Col, Row, Section } from 'components/grid'
import { PageProps, graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'

export default function DecodableTeacherGuides({ location }: PageProps) {
	const data = useStaticQuery<ResourceQuery>(query).allContentfulResource.edges
	const params = new URLSearchParams(location.search)
	const resourceId = params.get('id')
	const [resource, setResource] = useState<Resource | null>(null)

	function handleResource(id: string) {
		const resource = data.find((d) => d.node.id === id)
		if (resource) setResource(resource.node)
	}

	useEffect(() => {
		if (resourceId) handleResource(resourceId)
	}, [resourceId])

	return (
		<Layout>
			<Section margin="mt-20">
				<Row>
					<Col width="w-full md:w-1/2 lg:w-1/4" className="mx-auto">
						{resource ? (
							<Resource protected={resource.document ? true : false} title={resource.title} text={resource.document ? resource.title : 'Coming Soon'} noZoom />
						) : (
							<span className="text-lg font-semibold">Resource Not Found</span>
						)}
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

interface Resource {
	id: string
	cover: {
		gatsbyImageData: IGatsbyImageData
	}
	title: string
	document: {
		file: {
			url: string
		}
	}
}

export interface ResourceQueryNode {
	node: Resource
}
interface ResourceQuery {
	allContentfulResource: {
		edges: ResourceQueryNode[]
	}
}

const query = graphql`
	query {
		allContentfulResource(
			filter: {
				title: {
					in: [
						"Digital Teacher Guide Grade K Unit 2"
						"Digital Teacher Guide Grade K Unit 3"
						"Digital Teacher Guide Grade K Unit 4"
						"Digital Teacher Guide Grade 1 Unit 1"
						"Digital Teacher Guide Grade 1 Unit 2"
						"Digital Teacher Guide Grade 1 Unit 3"
						"Digital Teacher Guide Grade 1 Unit 4"
					]
				}
			}
		) {
			edges {
				node {
					id
					cover {
						gatsbyImageData
					}
					title
					document {
						file {
							url
						}
					}
				}
			}
		}
	}
`
